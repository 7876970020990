import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import {
  Alert,
  Box,
  Modal,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  StyleCommonButton,
  StyleModalCard
} from "../../css/styles";

function AssignProjectsModals(props) {
  const vertical = "top";
  const horizontal = "center";
  return (
    <>
      {/* Confirmation Modal for remove project assignment or not */}
      <Modal open={props.confirmationModal}>
        <StyleModalCard>
          <Box margin={2}>
            <Typography variant="h4" component="h2" margin={1}>
              {" "}
              Are you sure?
            </Typography>
            You want to remove this project assignment?
          </Box>
          <Box margin={4}>
            <StyleCommonButton
              onClick={(e) => props.handleCloseConfirmation(e, "No")}
            >
              No
            </StyleCommonButton>
            <StyleCommonButton
              variant="contained"
              onClick={(e) => props.handleCloseConfirmation(e, "Yes")}
              sx={{
                color: '#fff',
                backgroundColor: "#003a5d",
                marginLeft: 2,
                "&.MuiButtonBase-root": {
                  "&:hover": { backgroundColor: "#003a5d" },
                },
              }}
            >
              Yes
            </StyleCommonButton>
          </Box>
        </StyleModalCard>
      </Modal>

      {/* Unchecked success Alert message */}
      <Snackbar
        open={props.successOpen}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={props.handleAlertClose}
      >
        <Alert
          onClose={props.handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Changes has been successfully saved!!
        </Alert>
      </Snackbar>

      {/* Validation Modal */}
      <Modal open={props.openError}>
        <StyleModalCard sx={{ borderRadius: "0px" }}>
          <Toolbar
            sx={{
              backgroundColor: "#FF2E2E",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box color="#fff" padding={2}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="fa-3x"
                color="secondary"
              />
            </Box>
          </Toolbar>
          <Typography variant="h5" component="h2" padding={1}>
            Error!
          </Typography>
          <Typography padding={1}>{props.errorMessage}</Typography>
          <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
            <StyleCommonButton
              variant="contained"
              sx={{
                backgroundColor: "#003a5d",
                borderRadius: "25px",
                color: '#fff',
                marginLeft: 2,
                "&.MuiButtonBase-root": {
                  "&:hover": { backgroundColor: "#003a5d" },
                },
              }}
              onClick={() => props.handleCloseError()}
            >
              Try again
            </StyleCommonButton>
          </Toolbar>
        </StyleModalCard>
      </Modal>
    </>
  );
}

export default AssignProjectsModals;
