import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewAllProjects } from '../Dashboard/DashboardAction';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  StyleCommonButton,
  StyledTableCell,
  StyledTableContainer,
  StyleFlexBox,
  StyleLoaderBox,
  StyleMainDiv,
  StyleModalCard
} from '../../css/styles';
import '../../css/style.css'
import { updateProjectStatus } from './ProjectListAction';
import { useRef } from 'react';
import { getCookies } from '../Login/LoginAction';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ProjectList() {
  const userInfo = getCookies('LoginDetails')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const timer = useRef();

  const [searchProject, setSearchProject] = useState('');
  const [projectStatus, setProjectStatus] = useState({});
  const projectList = useSelector(state => state.dashboard.projectList);

  // console.log(projectList)
  const [activeOpen, setActiveOpen] = useState(false)
  const [deactiveOpen, setDeactiveOpen] = useState(false)

  // Alert box open
  const handleAlertClick = (status) => {
    if (status === 'Active') {
      // console.log(status)
      setActiveOpen(true);
    } else {
      setDeactiveOpen(true);
    }
  };

  // Alert box close
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setActiveOpen(false);
    setDeactiveOpen(false);
  };
  const vertical = 'top';
  const horizontal = 'center';

  useEffect(() => {
    dispatch(viewAllProjects(userInfo));
    // loader
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [])

  // confirmation modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  function handleOpenConfirmationModal(e, value) {
    setOpenConfirmationModal(true);
    setProjectStatus({ id: value.id, is_active: value.is_active })
  }
  function handleCloseConfirmationModal(e, para) {
    setOpenConfirmationModal(false);
    if (para === "Yes") {
      dispatch(updateProjectStatus(userInfo, projectStatus, (status) => handleAlertClick(status)));
    }
  }
  return (
    <>
      {/* Alert Box for status*/}
      <Snackbar open={activeOpen} anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Project has been activated successfully!!
        </Alert>
      </Snackbar>
      <Snackbar open={deactiveOpen} anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          Project has been deactivated successfully!!
        </Alert>
      </Snackbar>
      {/*  */}
      <StyleMainDiv>
        {loading ?
          <StyleLoaderBox>
            <CircularProgress sx={{ color: '#003a5d' }} ref={timer} />
          </StyleLoaderBox>
          :
          <Container component={Paper} maxWidth='md'>
            <Grid container spacing={2} marginTop={5}>
              <Grid item xs={12}>
                {/* Search user */}
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Typography component='h4' variant='small' margin='6px'>Search: </Typography>
                  <TextField size='small' value={searchProject} onChange={(e) => setSearchProject(e.target.value)}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </InputAdornment>,
                    }} />
                </Box>
              </Grid>
            </Grid>
            <StyledTableContainer>

              <Table sx={{ minWidth: 700, marginTop: '20px', marginBottom: '20px' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='center'>Project ID</StyledTableCell>
                    <StyledTableCell align='center'>Project Name</StyledTableCell>
                    <StyledTableCell align='center'>Project Backend Link</StyledTableCell>
                    <StyledTableCell align='center'>Project Frontend Link</StyledTableCell>
                    <StyledTableCell align='center'>Status</StyledTableCell>
                    <StyledTableCell align='center'>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {projectList.length === 0 ?
                    <TableRow>
                      <StyledTableCell colSpan={6} align="center" style={{ verticalAlign: 'top' }} >
                        <Typography  >
                          NO DATA FOUND
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                    :
                    <>
                      {projectList.filter(item => item.project.toLowerCase().includes(searchProject.toLowerCase()))
                        .map((value, index) => {
                          return (
                            <TableRow key={index} >
                              <StyledTableCell align='center'> {value.id}</StyledTableCell>
                              <StyledTableCell align='center'>{value.project}</StyledTableCell>
                              <StyledTableCell align='center'>{value.backend_link}</StyledTableCell>
                              <StyledTableCell align='center'>{value.frontend_link}</StyledTableCell>
                              <StyledTableCell align='center'>{value.is_active ? 'Active' : 'Inactive'}</StyledTableCell>
                              <StyledTableCell align='center'>
                                <Tooltip title='Change project status.' arrow>
                                  <span>
                                    <IconButton size='small' onClick={(e) => handleOpenConfirmationModal(e, value)}>
                                      <img src={require('../../img/set-status.png')} className='imageButtons'
                                        style={{ padding: '5px 5px 5px 5px' }} alt="set status" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </StyledTableCell>
                            </TableRow>
                          )
                        })}
                    </>}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Container>
        }
      </StyleMainDiv>
      {/* confirmation modal */}
      <Modal
        open={openConfirmationModal}>
        <StyleModalCard>
          <Box margin={2}>
            <Typography variant='h4' component='h2' margin={1}> Are you sure?</Typography>
            You want to change project status?
          </Box>
          <Box margin={4}>
            <StyleCommonButton onClick={(e) => handleCloseConfirmationModal(e, "No")}
            >
              No
            </StyleCommonButton>
            <StyleCommonButton variant='contained'
              onClick={(e) => handleCloseConfirmationModal(e, "Yes")}
              sx={{
                color: '#fff',
                backgroundColor: '#003a5d',
                marginLeft: 2,
                '&.MuiButtonBase-root': { '&:hover': { backgroundColor: '#003a5d' } }
              }}
            >
              Yes
            </StyleCommonButton>
          </Box>
        </StyleModalCard>
      </Modal>

    </>
  );
}

export default ProjectList;
