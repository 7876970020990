import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Select,
  IconButton,
  Button,
} from "@mui/material";
import {
  StyleCommonButton,
  StyleFlexBox
} from "../../css/styles";
import { useEffect, } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  getActiveProjects,
} from "./AssignProjectsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getCookies } from "../Login/LoginAction";

function RegistrationFormComponent(props) {
  const userInfo = getCookies("LoginDetails");
  const dispatch = useDispatch();
  const activeProjectList = useSelector(state => state.userProjectModules.activeProjectList)
  console.log(props.projectModuleList)
  useEffect(() => {
    dispatch(getActiveProjects(userInfo))
  }, []);
  var orderAuditUserRole = ''
  var orderAuditobj = props.fieldObj.filter(e => e.project_id == 4);
  // console.log(orderAuditobj)
  if (orderAuditobj.length !== 0) {
    orderAuditobj[0].form_fields.forEach(element => {
      // console.log(element)
      if (element.formField == 'User_Role') {
        if (element.fieldValue == 2) {
          orderAuditUserRole = 2
        }
      }
    });
  }

  return (
    <>
      {props.projectModuleList.map((value, index) => {
        let present = activeProjectList.findIndex(e => e.project === value.project);
        var module_id = [];
        var user_module = [];
        var mandatory_fields = [];
        var fieldType = [];
        var fieldValue = [];
        var fieldID = [];
        var userfieldID = [];
        if (value.module_id_str !== null) {
          value.module_id_str
            .split(",")
            .map((v1) => module_id.push(Number(v1)));
        }
        if (value.user_module_id !== null) {
          value.user_module_id
            .split(",")
            .map((v2) => user_module.push(Number(v2)));
        }
        if (value.is_mandatory !== null) {
          value.is_mandatory.split(",").map((v3) => mandatory_fields.push(v3));
        }
        if (value.field_type !== null) {
          value.field_type.split(",").map((v4) => fieldType.push(v4));
        }
        if (value.user_field_value !== null) {
          value.user_field_value.split(",").map((v5) => fieldValue.push(v5));
        }
        if (value.field_id !== null) {
          value.field_id.split(",").map((v6) => fieldID.push(v6));
        }
        if (value.user_field_id !== null) {
          value.user_field_id.split(",").map((v7) => userfieldID.push(v7));
        }

        return (
          <>
            {present >= 0 &&
              <Box key={index}>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Checkbox
                      key={index}
                      name="user_project_id"
                      value={value.project_id}
                      defaultChecked={
                        value.user_project_id !== null ? true : false
                      }
                      onChange={(e) =>
                        props.handleChange(e, index, "Project", value.project_id)
                      }
                    />
                    <b>{value.project}</b>
                  </Box>
                  {value.user_project_id !== null && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        props.handleOpenConfirmation(value.project_id)
                      }
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </IconButton>
                  )}
                </Box>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <Grid container spacing={1}>
                      {value.user_project_id !== null && value.module !== null
                        ? value.module.split(",").map((val, i1) => (
                          <Grid item xs={4} key={i1}>
                            <Checkbox size="small"
                              defaultChecked={
                                user_module.includes(module_id[i1])
                                  ? true
                                  : false
                              }
                              value={module_id[i1]}
                              name="user_module_id"
                              onChange={(e) =>
                                props.handleChange(
                                  e,
                                  index,
                                  "Module",
                                  value.project_id
                                )
                              }
                            />
                            {val}
                          </Grid>
                        ))
                        : null}
                    </Grid>
                  </li>
                </ul>
                {value.user_project_id !== null && value.form_field !== null ? (
                  <>
                    <StyleFlexBox marginTop={4}>
                      <u>Registration Fields</u>
                    </StyleFlexBox>
                    {props.fieldObj[index] &&
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          paddingRight: "6%",
                          paddingLeft: "6%",
                          marginTop: "1%",
                        }}
                      >
                        {props.fieldObj[index].form_fields.map((val2, ind2) =>
                        (
                          <Grid item xs={6} key={ind2}>
                            <>


                              {val2.field_type === "dropdown" ? (
                                <>
                                  <Typography>
                                    {val2.formField.replace(/_/g, " ")} {/*for REMOVE UNDERSCORE*/}
                                    {val2.is_mandatory === "1" && (
                                      <label style={{ color: "red" }}>*</label>
                                    )}
                                  </Typography>
                                  <Select
                                    fullWidth
                                    size="small"
                                    value={val2.fieldValue}
                                    onChange={(e) =>
                                      props.handleChange(
                                        e,
                                        index,
                                        "Registration",
                                        value.project_id,
                                        val2.fieldID
                                      )
                                    }
                                  >

                                    {props.dropdownList.length !== 0 && props.dropdownList.filter((val3, ind3) => val3 !== undefined && val3.project_id === value.project_id).map((item, position) => {
                                      var menuVar
                                      for (var key in item) {
                                        if (item.hasOwnProperty(key)) {
                                          var propValue = item[key];
                                          if (key === val2.formField.toLowerCase()) {
                                            menuVar = propValue.map((val4, ind4) => {
                                              for (var propName in val4) {
                                                if (val4.hasOwnProperty(propName)) {
                                                  var propValue1 = val4[propName];
                                                  if (propName === val2.formField.toLowerCase()) {
                                                    return (<MenuItem value={val4.id}>{propValue1}</MenuItem>)
                                                  }
                                                }
                                              }
                                            })
                                          }
                                        }
                                      }
                                      return (
                                        menuVar
                                      )
                                    })
                                    }
                                  </Select>
                                </>
                              ) :
                                <>
                                  {props.fieldObj[index].project_id != 4 && (
                                    <>
                                      <Typography>
                                        {val2.formField.replace(/_/g, " ")} {/*for REMOVE UNDERSCORE*/}
                                        {val2.is_mandatory === "1" && (
                                          <label style={{ color: "red" }}>*</label>
                                        )}
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        size="small"
                                        value={val2.fieldValue || ''}
                                        onChange={(e) =>
                                          props.handleChange(
                                            e,
                                            index,
                                            "Registration",
                                            value.project_id,
                                            val2.fieldID
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                  {/* In order audit there is a condition when admin select auditor(2) 
                                  user role then render password, confirm password and clock id field as well 
                                  as the password and confirm password field have values then the field is hide*/}
                                  {props.fieldObj[index].project_id == 4 && orderAuditUserRole == 2 && (
                                    <>
                                      {val2.formField == 'Password' && (props.passPresent == true ?
                                        null :
                                        <>
                                          <Typography>
                                            {val2.formField.replace(/_/g, " ")} {/*for REMOVE UNDERSCORE*/}
                                            <label style={{ color: "red" }}>*</label>
                                          </Typography>
                                          <TextField
                                            fullWidth
                                            type='password'
                                            size="small"
                                            value={val2.fieldValue || ''}
                                            onChange={(e) =>
                                              props.handleChange(
                                                e,
                                                index,
                                                "Registration",
                                                value.project_id,
                                                val2.fieldID
                                              )
                                            }
                                          />
                                        </>)
                                        ||
                                        val2.formField == 'Confirm_Password' && (props.passPresent == true ? null :
                                          <>
                                            <>
                                              <Typography>
                                                {val2.formField.replace(/_/g, " ")} {/*for REMOVE UNDERSCORE*/}
                                                <label style={{ color: "red" }}>*</label>
                                              </Typography>
                                              <TextField
                                                fullWidth
                                                type='password'
                                                size="small"
                                                value={val2.fieldValue || ''}
                                                onChange={(e) =>
                                                  props.handleChange(
                                                    e,
                                                    index,
                                                    "Registration",
                                                    value.project_id,
                                                    val2.fieldID
                                                  )
                                                }
                                              />
                                            </>
                                          </>)
                                        ||
                                        val2.formField == 'Clock_ID' && (
                                          <>
                                            <Typography>
                                              {val2.formField.replace(/_/g, " ")} {/*for REMOVE UNDERSCORE*/}
                                              {val2.is_mandatory === "1" && (
                                                <label style={{ color: "red" }}>*</label>
                                              )}
                                            </Typography>
                                            <TextField
                                              fullWidth
                                              size="small"
                                              value={val2.fieldValue || ''}
                                              onChange={(e) =>
                                                props.handleChange(
                                                  e,
                                                  index,
                                                  "Registration",
                                                  value.project_id,
                                                  val2.fieldID
                                                )
                                              }
                                            />
                                          </>
                                        )}


                                    </>
                                  )}
                                </>
                              }
                            </>
                          </Grid>
                        ))}


                      </Grid>
                    }

                  </>

                ) : null}

                {/* save button */}
                {value.user_project_id !== null && (
                  <StyleFlexBox marginTop={2}>
                    <StyleCommonButton
                      variant="contained"
                      type="submit"
                      onClick={(e) =>
                        props.handleSubmitForm(e, value.user_project_id, index)
                      }
                      sx={{
                        color: '#fff',
                        backgroundColor: "#003a5d",
                        marginLeft: 2,
                        "&.MuiButtonBase-root": {
                          "&:hover": { backgroundColor: "#003a5d" },
                        },
                      }}
                    >
                      Save
                    </StyleCommonButton>
                  </StyleFlexBox>
                )}
              </Box>
            }
          </>
        );
      })}
    </>
  );
};

export default RegistrationFormComponent;
