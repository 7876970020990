import { Box } from '@mui/material';
import React from 'react';
import ForgotPassCard from '../../components/ForgotPass/ForgotPassCard';
import { backImg } from '../../css/styles';

function ForgotPassPage() {
    return (
        <Box style={backImg.paperContainer}>
            <ForgotPassCard />
        </Box>
    );
}

export default ForgotPassPage;
