import { Box, CircularProgress, Typography } from "@mui/material";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StyleBox,
  StyleLoaderBox,
  StyleMainDiv,
  StyleTiles,
} from "../../css/styles";
import { getCookies } from "../Login/LoginAction";
import {
  getUserProjects,
  setNavCookies,
  viewAllProjects,
  setNavUrlCookies,
} from "./DashboardAction";

function DashboardComponents() {
  const userInfo = getCookies("LoginDetails");
  const dispatch = useDispatch();
  const UserProjectsList = useSelector(
    (state) => state.dashboard.getUserProjectsList
  );
  const [loading, setLoading] = useState(false);
  const timer = useRef();
  // const [navLink, setNavLink] = useState([])

  // for navigation on project
  function navigateToProj(frontend_link, project_name) {
    var navLink = [];
    const navLinks1 = getCookies("NavigationUrls");
    if (typeof navLinks1 !== "string") {
      navLink = navLinks1;
      if (!navLink.includes(frontend_link)) {
        navLink.push(frontend_link);
      }
    } else {
      if (!navLink.includes(frontend_link)) {
        navLink.push(frontend_link);
      }
    }
    setNavUrlCookies(navLink);

    var navUrls = [];
    const navUrls1 = getCookies("NavigationLinks");
    if (typeof navUrls1 !== "string") {
      navUrls = navUrls1;
      if (!navUrls.includes(project_name)) {
        navUrls.push(project_name);
      }
    } else {
      if (!navUrls.includes(project_name)) {
        navUrls.push(project_name);
      }
    }
    setNavCookies(navUrls);
    var form = document.createElement("form");
    document.body.appendChild(form);
    form.target = project_name;
    form.method = "post";
    form.action = `${frontend_link}/login-launchpad?token=${userInfo.data.jwt}&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
    var map = window.open("", project_name);

    if (map) {
      form.submit();
    }
    document.body.removeChild(form);
  }
  useEffect(() => {
    const id = {
      user_id: userInfo.data.user_id,
    };

    // loader
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
        dispatch(viewAllProjects(userInfo));
        dispatch(getUserProjects(userInfo, id));
      }, 500);
    }
  }, []);
  return (
    <StyleMainDiv>
      {loading ? (
        <StyleLoaderBox>
          <CircularProgress sx={{ color: "#003a5d" }} ref={timer} />
        </StyleLoaderBox>
      ) : (
        <StyleBox>
          {UserProjectsList.length === 0 ? (
            <Box>
              <Typography>NO DATA FOUND</Typography>
            </Box>
          ) : (
            <>
              {UserProjectsList.map((value, index) => {
                // console.log(value)
                return (
                  <StyleTiles
                    key={index}
                    onClick={() => navigateToProj(value.frontend_link, value.project)}
                    sx={{
                      marginTop: { sm: "10px", md: "20px", xs: "10px" },
                      marginBottom: { sm: "10px", md: "20px", xs: "10px" },
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h6"
                      sx={{ marginTop: "65px" }}
                    >
                      {value.project}
                    </Typography>
                  </StyleTiles>
                );
              })}
            </>
          )}
        </StyleBox>
      )}
    </StyleMainDiv>
  );
}

export default DashboardComponents;
