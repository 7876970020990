import React from 'react';
import AddUserForm from '../../components/AddNewUser/AddUserForm';
import Header from '../../components/Header/Header'

function AddUserPage() {
    return (
        <>
            <Header />
            <AddUserForm />
        </>
    );
}

export default AddUserPage;
