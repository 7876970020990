import { GET_USER_PROJECTS, VIEW_ALL_PROJECTS } from "./DashboardActionType";
import * as CryptoJS from "crypto-js";

// Get All Projects
export const viewAllProjects = (userInfo) => {
  return (dispatch, getState) => {
    fetch(`${process.env.REACT_APP_URL}/getAllProjects`, {
      method: "GET",
      headers: {
        Authorization: `Token ${userInfo.data.jwt}`,
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => response.json())
      .then((projectList) => {
        console.log("projectList", projectList);
        dispatch({ type: VIEW_ALL_PROJECTS, payload: projectList });
      });
  };
};

// get active projects for user
export const getUserProjects = (userInfo, id) => {
  console.log(id);
  return (dispatch, getState) => {
    fetch(`${process.env.REACT_APP_URL}/getUserProjects`, {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        Authorization: `Token ${userInfo.data.jwt}`,
        "ngrok-skip-browser-warning": "true",
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((getUserProjectsList) => {
        console.log(".............", getUserProjectsList);
        dispatch({ type: GET_USER_PROJECTS, payload: getUserProjectsList });
      });
  };
};

// set project name cookies
export function setNavCookies(navLink) {
  console.log("......", navLink);
  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let data = CryptoJS.AES.encrypt(JSON.stringify(navLink), "abc").toString();
  document.cookie =
    "NavigationLinks" + "=" + JSON.stringify(data) + ";" + expires + ";path=/";
}

// set project url cookies
export function setNavUrlCookies(navLink) {
  console.log("......", navLink);
  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let data = CryptoJS.AES.encrypt(JSON.stringify(navLink), "abc").toString();
  document.cookie =
    "NavigationUrls" + "=" + JSON.stringify(data) + ";" + expires + ";path=/";
}

//get cookies
export function getNavCookies(cookieName) {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let decr = CryptoJS.AES.decrypt(
        JSON.parse(c.substring(name.length, c.length)),
        "abc"
      );
      return JSON.parse(decr.toString(CryptoJS.enc.Utf8));
    }
  }
  return "";
}
