import {
    USER_REGISTRATION,
    VIEW_ALL_ROLES,
} from "./UserRegistrationActionType";

// user registration
export const userRegistration = (userInfo, userDetails, navToUserList, handleAlertClick) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/userRegistration`, {
            method: "POST",
            body: JSON.stringify(userDetails),
            headers: { "Content-type": "application/json", "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.json())
            .then(json => {
                console.log("response", json.status)
                if (json.status === 'Registration Successful.') {
                    navToUserList();
                } else if (json.status === 'User already exists.') {
                    handleAlertClick();
                }
            });
        dispatch({ type: USER_REGISTRATION })
    }
};

// get all user role
export const viewAllRoles = (userInfo) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/getAllRoles`, {
            method: "GET",
            headers: { "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.json())
            .then(projectList => {
                dispatch({ type: VIEW_ALL_ROLES, payload: projectList });
            }
            )
    }
};

