import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  StyleCommonButton,
  StyledTableCell,
  StyledTableContainer,
  StyleLoaderBox,
  StyleMainDiv,
  StyleModalCard
} from '../../css/styles';
import {
  updateUserStatus,
  updateUserStatusinAll,
  viewAllUsers
} from './UsersAction';
import '../../css/style.css'
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { getCookies } from '../Login/LoginAction';
import { viewAllProjects } from '../Dashboard/DashboardAction';
import { projectsBackendLink, } from '../AssignProjects/AssignProjectsAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function UsersList() {
  const userInfo = getCookies('LoginDetails');
  console.log(userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const timer = useRef();

  const userList = useSelector(state => state.users.userList);
  // console.log(userList)
  const [searchUser, setSearchUser] = useState('');
  const [activeOpen, setActiveOpen] = useState(false);
  const [deactiveOpen, setDeactiveOpen] = useState(false);
  const [userStatusEach, setUserStatusEach] = useState({}); //user status for deactive user from all projects;
  const [userID, setUserID] = useState({}) // user id for get user project for active/deactivate user from assign projects
  const projectDetails = useSelector(state => state.dashboard.projectList);
  console.log(projectDetails)

  const [userStatus, setUserStatus] = useState({})
  // Alert box open
  const handleAlertClick = (status) => {
    if (status === 'Active') {
      // console.log(status)
      setActiveOpen(true);
    } else {
      setDeactiveOpen(true);
    }
  };

  // Alert box close
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setActiveOpen(false);
    setDeactiveOpen(false);
  };
  const vertical = 'top';
  const horizontal = 'center';

  // Confirmation Modal
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  function handleOpenConfirmationModal(e, value) {
    console.log('value', value)
    setOpenConfirmationModal(true);
    setUserStatus({ user_id: value.user_id, active_user_flag: value.active_user_flag, });
    setUserStatusEach({ user_email: value.email_id, active_user_flag: value.active_user_flag, launchpad_token: userInfo.data.jwt, });
    setUserID({ user_id: value.user_id });
  }
  function handleCloseConfirmationModal(e, para) {
    setOpenConfirmationModal(false)
    if (para === "Yes") {
      dispatch(updateUserStatus(userInfo, userStatus, (status) => handleAlertClick(status)))

      // update user status(active/deactivate user from assign projects).
      dispatch(updateUserStatusinAll(userInfo, userStatusEach, userID))
    }

  }
  // navigate to assign project ,modules and registration fields
  function navToAssignProjects(userValue) {
    const userData = {
      id: userValue.user_id,
      email_id: userValue.email_id,
      first_name: userValue.first_name,
      last_name: userValue.last_name,
      phone_no: userValue.phoneNumber,
    }
    localStorage.setItem('User_Data', JSON.stringify(userData));
    navigate('/dashboard/users/assignProjects')
  }
  useEffect(() => {
    dispatch(viewAllUsers(userInfo));
    dispatch(viewAllProjects(userInfo));
    dispatch(projectsBackendLink(userInfo));

    // loader
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);
  return (
    <>
      {/* Alert Box for status*/}
      <Snackbar open={activeOpen} anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          User has been activated successfully!!
        </Alert>
      </Snackbar>
      <Snackbar open={deactiveOpen} anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="warning" sx={{ width: '100%' }}>
          User has been deactivated successfully!!
        </Alert>
      </Snackbar>
      {/*  */}

      <StyleMainDiv>


        {loading ?
          <StyleLoaderBox>
            <CircularProgress sx={{ color: '#003a5d' }} ref={timer} />
          </StyleLoaderBox>
          :
          <Container component={Paper} maxWidth='md'>
            <Grid container spacing={2} marginTop={5}>
              <Grid item xs={12}>
                {/* Search user */}
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Typography component='h4' variant='small' margin='6px'>Search: </Typography>
                  <TextField size='small' value={searchUser}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </InputAdornment>,
                    }}
                    onChange={(e) => setSearchUser(e.target.value)} />
                </Box>
              </Grid>
            </Grid>

            <StyledTableContainer>

              <Table sx={{ minWidth: 700, marginTop: '20px', marginBottom: '20px' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='center'>User ID</StyledTableCell>
                    <StyledTableCell align='center'>First Name</StyledTableCell>
                    <StyledTableCell align='center'>Last Name</StyledTableCell>
                    <StyledTableCell align='center'>Email</StyledTableCell>
                    <StyledTableCell align='center'>Role Name</StyledTableCell>
                    <StyledTableCell align='center'>Assign Projects</StyledTableCell>
                    <StyledTableCell align='center'>Status</StyledTableCell>
                    <StyledTableCell align='center'>Activated/Deactived</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {userList.length === 0 ?
                    <TableRow>
                      <StyledTableCell colSpan={8} align="center" style={{ verticalAlign: 'top' }} >
                        <Typography  >
                          NO DATA FOUND
                        </Typography>
                      </StyledTableCell>
                    </TableRow> :
                    <>
                      {userList.filter(item => item.first_name.toLowerCase().includes(searchUser.toLowerCase()) ||
                        item.last_name.toLowerCase().includes(searchUser.toLowerCase()) ||
                        item.email_id.toLowerCase().includes(searchUser.toLowerCase()) ||
                        item.role_name.toLowerCase().includes(searchUser.toLowerCase())
                      ).map((value, index) => {
                        return (
                          <TableRow key={index} >
                            <StyledTableCell align='center'> {value.user_id}</StyledTableCell>
                            <StyledTableCell align='center'>{value.first_name}</StyledTableCell>
                            <StyledTableCell align='center'>{value.last_name}</StyledTableCell>
                            <StyledTableCell align='center'>{value.email_id}</StyledTableCell>
                            <StyledTableCell align='center'>{value.role_name} </StyledTableCell>
                            <StyledTableCell align='center' >
                              <Tooltip arrow
                                title={value.active_user_flag === false ? "You can't assign projects to deactivated user." : ''}>
                                <span>
                                  <IconButton size='small' disabled={value.active_user_flag === false ? true : false} onClick={() => navToAssignProjects(value)}>
                                    <img src={require('../../img/edit.png')} className='imageButtons' alt="edit"
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align='center'>{value.active_user_flag ? 'Active' : 'Inactive'}</StyledTableCell>
                            <StyledTableCell align='center'>
                              <Tooltip title='Change user status.' arrow>
                                <IconButton size='small' onClick={(e) => handleOpenConfirmationModal(e, value)}>
                                  <span>
                                    <img src={require('../../img/set-status.png')} className='imageButtons'
                                      style={{ padding: '5px 5px 5px 5px' }} alt="set status" />
                                  </span>
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>

                          </TableRow>
                        )
                      })}
                    </>}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Container>
        }
      </StyleMainDiv>
      {/* confirmation modal */}
      <Modal
        open={openConfirmationModal}>
        <StyleModalCard>
          <Box margin={2}>
            <Typography variant='h4' component='h2' margin={1}> Are you sure?</Typography>
            You want to change user status?
          </Box>
          <Box margin={4}>
            <StyleCommonButton onClick={(e) => handleCloseConfirmationModal(e, "No")}
            >
              No
            </StyleCommonButton>
            <StyleCommonButton variant='contained'
              onClick={(e) => handleCloseConfirmationModal(e, "Yes")}
              sx={{
                color: '#fff',
                backgroundColor: '#003a5d',
                marginLeft: 2,
                '&.MuiButtonBase-root': { '&:hover': { backgroundColor: '#003a5d' } }
              }}
            >
              Yes
            </StyleCommonButton>
          </Box>
        </StyleModalCard>
      </Modal>

    </>
  );
}

export default UsersList;
