import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Link,
  Menu,
  MenuItem,
  Toolbar,
  IconButton,
  CssBaseline,
  Box,
  AppBar,
  Fade,
  Typography,
} from "@mui/material";
import { Img, StyleCommonButton, StyleUserName } from "../../css/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import SideDrawer from "./SideDrawer";
import { getCookies } from "../Login/LoginAction";
import { useDispatch } from "react-redux";
import { logout } from "./HeaderAction";

export const drawerWidth = 270;

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const userInfo = getCookies("LoginDetails");
  console.log(userInfo);

  // open drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //Change Password modal
  const [openPassModal, setOpenPassModal] = React.useState(false);
  const handleOpenPassModal = () => {
    setOpenPassModal(true);
    handleClose();
  };
  const handleClosePassModal = () => {
    setOpenPassModal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const opendrop = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  function handleLogout(e) {
    handleClose();
    const navLinks = getCookies("NavigationLinks");
    const navigationUrls = getCookies("NavigationUrls");
    if (typeof navLinks !== "string") {
      dispatch(logout(navLinks, navigationUrls));
      e.preventDefault();
    }

    setTimeout(function () {
      // delete cookies from browser
      document.cookie =
        "LoginDetails=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie =
        "NavigationLinks=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      document.cookie =
        "NavigationUrls=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

      navigate("/"); //navigate to login page
    }, 1000);
  }

  useEffect(() => {
    // console.log("Header", userInfo === '', typeof (userInfo))
    if (typeof userInfo === "string") {
      navigate("/");
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#dbf2ff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            // color="#000"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            display="none"
            sx={{ mr: 2, color: "#000", display: { sm: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, padding: 1 }}
          >
            <Link href="/dashboard">
              <Img
                src={require("../../img/logo.png")}
                className="d-inline-block align-top"
                alt=""
              />
            </Link>
          </Typography>
          <StyleCommonButton
            color="inherit"
            variant="text"
            id="fade-button"
            aria-controls={opendrop ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opendrop ? "true" : undefined}
            onClick={handleClick}
            endIcon={<FontAwesomeIcon icon={faCaretDown} />}
            sx={{ color: "#003a5d" }}
          >
            {userInfo && (
              <StyleUserName>
                {userInfo.data.first_name + " " + userInfo.data.last_name}
              </StyleUserName>
            )}
          </StyleCommonButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={opendrop}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={(e) => handleOpenPassModal(e)}>
              Change Password
            </MenuItem>
            <MenuItem onClick={(e) => handleLogout(e)}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {/* SideDrawer component */}
      <SideDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />

      {/* Chnage Password Modal */}
      <ChangePasswordModal
        openPassModal={openPassModal}
        handleClosePassModal={handleClosePassModal}
      />
    </Box>
  );
}

export default Header;
