import {
    GET_USER_PROJECTS,
    VIEW_ALL_PROJECTS
} from "./DashboardActionType";

const dashboardObj = {
    projectList: [],
    getUserProjectsList: [],
};

const dashboardReducer = (state = dashboardObj, action) => {

    switch (action.type) {
        case VIEW_ALL_PROJECTS:
            return {
                ...state, projectList: action.payload
            };
        case GET_USER_PROJECTS:
            return {
                ...state, getUserProjectsList: action.payload
            };
        default:
            return state;
    }
}

export default dashboardReducer;
