import { FORGOT_PASSWORD } from "./ForgotPassActionType";

export const forgotPassword = (emailDetails, handleOpenPassModal, handleClick) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/forgotpassword`, {
            method: "POST",
            body: JSON.stringify(emailDetails),
            headers: { "Content-type": "application/json", "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.text())
            .then(json => {
                // console.log("response", json)
                if (json === 'Password Reset Successful.') {
                    handleOpenPassModal();
                }
                else {
                    handleClick();
                }
            });
        dispatch({ type: FORGOT_PASSWORD })
    }
}
