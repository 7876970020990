import {
  Divider,
  Modal,
  TextField,
  Toolbar,
  Box,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  StyleModalCard,
  StyleCommonButton,
  StyleFlexBox,
} from "../../css/styles";
import { getCookies } from "../Login/LoginAction";
import { changePassword, } from "./HeaderAction";

function ChangePasswordModal(props) {
  const userInfo = getCookies("LoginDetails");
  const dispatch = useDispatch();
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);
  const [passDetails, setPassDetails] = useState({
    oldPass: "",
    newPass: "",
    confirmNewPass: "",
  });
  const [error, setError] = useState({
    oldPass: "",
    newPass: "",
    confirmNewPass: "",
  });
  // Alert box open
  const handleAlertClick = (para) => {
    if (para === "Success") {
      setSuccessOpen(true);
    } else {
      setFailOpen(true);
    }
  };
  // Alert box close
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessOpen(false);
    setFailOpen(false);
  };
  const vertical = "top";
  const horizontal = "center";

  function handlePassDChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setPassDetails({ ...passDetails, [name]: value });
    setError({ ...error, [name]: "" });
  }
  function resetPassDetails() {
    passDetails.oldPass = "";
    passDetails.newPass = "";
    passDetails.confirmNewPass = "";
  }
  function handleChangePassword(e) {
    if (passDetails.oldPass.length === 0) {
      setError({ ...error, oldPass: "Please enter old password" });
      if (passDetails.newPass.length === 0) {
        setError({
          ...error,
          oldPass: "Please enter old password",
          newPass: "Please enter new password",
        });
        if (passDetails.confirmNewPass.length === 0) {
          // console.log('hiiiii')
          setError({
            ...error,
            oldPass: "Please enter old password",
            newPass: "Please enter new password",
            confirmNewPass: "Please enter confirm new password",
          });
          // console.log(error)
        }
      } else if (passDetails.confirmNewPass.length === 0) {
        setError({
          ...error,
          oldPass: "Please enter old password",
          confirmNewPass: "Please enter confirm new password",
        });
      }
    } else if (passDetails.newPass.length === 0) {
      setError({ ...error, newPass: "Please enter new password" });
      if (passDetails.confirmNewPass.length === 0) {
        setError({
          ...error,
          newPass: "Please enter new password",
          confirmNewPass: "Please enter confirm new password",
        });
      }
    } else if (passDetails.confirmNewPass.length === 0) {
      setError({
        ...error,
        confirmNewPass: "Please enter confirm new password",
      });
    } else if (passDetails.newPass !== passDetails.confirmNewPass) {
      setError({ ...error, confirmNewPass: "Password must be same" });
    } else if (
      passDetails.oldPass !== 0 &&
      passDetails.newPass !== 0 &&
      passDetails !== 0
    ) {
      // console.log(userInfo)
      const passwordDetails = {
        user_changePss_id: userInfo.data.user_id,
        old_password: passDetails.oldPass,
        password: passDetails.newPass,
        username: userInfo.username,
      };
      dispatch(
        changePassword(
          userInfo,
          passwordDetails,
          () => props.handleClosePassModal(e),
          (para) => handleAlertClick(para),
          () => resetPassDetails()
        )
      );
      // console.log('obj', passwordDetails)
    }
    // console.log(error)
    // props.handleClosePassModal();
  }
  return (
    <>
      <Snackbar
        open={successOpen}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Password has been changed successfully!!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failOpen}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Password did not match!!
        </Alert>
      </Snackbar>
      <Modal open={props.openPassModal}>
        <StyleModalCard>
          <Toolbar />
          <Divider />
          <Typography variant="h5" component="h2" marginTop={2}>
            Change Your Password
          </Typography>
          <StyleFlexBox marginTop={2}>
            <TextField
              size="small"
              type="password"
              placeholder="Old Password"
              style={{ width: 300 }}
              error={error.oldPass ? true : false}
              helperText={error.oldPass}
              name="oldPass"
              value={passDetails.oldPass}
              onChange={(e) => handlePassDChange(e)}
            />
          </StyleFlexBox>
          <StyleFlexBox marginTop={2}>
            <TextField
              size="small"
              type="password"
              placeholder="New Password"
              style={{ width: 300 }}
              error={error.newPass ? true : false}
              helperText={error.newPass}
              name="newPass"
              value={passDetails.newPass}
              onChange={(e) => handlePassDChange(e)}
            />
          </StyleFlexBox>
          <StyleFlexBox marginTop={2} marginBottom={2}>
            <TextField
              size="small"
              type="password"
              placeholder="Confirm New Password"
              style={{ width: 300 }}
              error={error.confirmNewPass ? true : false}
              helperText={error.confirmNewPass}
              name="confirmNewPass"
              value={passDetails.confirmNewPass}
              onChange={(e) => handlePassDChange(e)}
            />
          </StyleFlexBox>
          <Divider />
          <Toolbar />

          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              marginBottom: 2,
              marginRight: 2,
            }}
          >
            <StyleCommonButton onClick={(e) => props.handleClosePassModal(e)}>
              Cancel
            </StyleCommonButton>
            <StyleCommonButton
              variant="contained"
              sx={{
                backgroundColor: "#003a5d",
                color: '#fff',
                marginLeft: 2,
                "&.MuiButtonBase-root": {
                  "&:hover": { backgroundColor: "#003a5d" },
                },
              }}
              onClick={(e) => handleChangePassword(e)}
            >
              Save
            </StyleCommonButton>
          </Box>
        </StyleModalCard>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
