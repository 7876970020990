import { CHANGE_PASSWORD, LOGOUT } from "./HeaderActionType";
import async from "async";

export const changePassword = (
  userInfo,
  passDetails,
  handleClosePassModal,
  handleAlertClick,
  resetPassDetails
) => {
  return (dispatch, getState) => {
    fetch(`${process.env.REACT_APP_URL}/changepassword`, {
      method: "POST",
      body: JSON.stringify(passDetails),
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${userInfo.data.jwt}`,
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => response.text())
      .then((json) => {
        if (json === "Success") {
          handleAlertClick("Success");
          // for closing modal
          handleClosePassModal();
          resetPassDetails();
        } else {
          handleAlertClick();
        }
      });
    dispatch({ type: CHANGE_PASSWORD });
  };
};

// logout in all projects
export const logout = (navLinks, navigationUrls) => {
  return (dispatch, getState) => {
    async.parallel(
      [
        function (callback) {
          navLinks.forEach((URL, index) => {
            var form = document.createElement("form");
            document.body.appendChild(form);
            form.target = URL;
            form.method = "post";
            form.action = `${navigationUrls[index]}/logout-launchpad`;
            var a = window.open("", URL);
            form.submit();
          });
        },
      ],
      function (err, results) {
        console.log("final", results);
        dispatch({ type: LOGOUT });
      }
    );
  };
};
