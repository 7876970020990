import { viewAllProjects } from "../Dashboard/DashboardAction";
import { UPDATE_PROJECT_STATUS } from "./ProjectListActionType";

// Active and Inactive project
export const updateProjectStatus = (userInfo, projectStatus, handleAlertClick) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/updateActiveProjects`, {
            method: "PUT",
            body: JSON.stringify(projectStatus),
            headers: { "Content-type": "application/json", "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.text())
            .then(json => {
                // console.log(json)
                if (json === 'Project is deactivated.') {
                    handleAlertClick()
                } else if (json === 'Project is activated.') {
                    handleAlertClick('Active')
                }
                dispatch(viewAllProjects(userInfo))
            });
        dispatch({ type: UPDATE_PROJECT_STATUS })
    }
}

