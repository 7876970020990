import {
    UPDATE_USER_STATUS,
    UPDATE_USER_STATUS_IN_ALL,
    VIEW_ALL_USERS
} from "./UsersActionType";
import { getUserProjects } from "../Dashboard/DashboardAction";

// Get All Projects
export const viewAllUsers = (userInfo) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/getAllUsers`, {
            method: "GET",
            headers: { "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.json())
            .then(userList => {
                dispatch({ type: VIEW_ALL_USERS, payload: userList });
            }
            )
    }
};

// Update User Status
export const updateUserStatus = (userInfo, userStatus, handleAlertClick) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/updateActiveUsers`, {
            method: "PUT",
            body: JSON.stringify(userStatus),
            headers: { "Content-type": "application/json", "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.text())
            .then(json => {
                if (json === 'User is activated.') {
                    handleAlertClick('Active')
                } else if (json === 'User is deactivated.') {
                    handleAlertClick()
                }
                dispatch(viewAllUsers(userInfo))
            });
        dispatch({ type: UPDATE_USER_STATUS })
    }
}

// update user status(active/deactivate user from assign projects).
export const updateUserStatusinAll = (userInfo, userStatusEach, userID) => {
    return (dispatch, getState) => {
        dispatch(getUserProjects(userInfo, userID))
        setTimeout(function () { //wait for active project list so set timeout
            const assignProjects = getState().dashboard.getUserProjectsList;
            console.log("assignProjects", assignProjects)
            let productPortIndex = assignProjects.findIndex(e => e.id == 3)

            console.log(productPortIndex)
            var projectBackLink = []
            var result = []
            // projectLink.length = 0;
            for (let i = 0; i < assignProjects.length; i++) {
                projectBackLink.push(assignProjects[i].backend_link)
            }
            console.log('projectBackLink', projectBackLink)
            // async.parallel([
            //     function (callback) {

            projectBackLink.forEach((URL, index) => {
                // conditionally header set for product portal 
                let header_var = {}
                if (index == productPortIndex) {
                    console.log(index == productPortIndex)
                    header_var = { "Content-type": "application/json" }
                }
                console.log(header_var)
                fetch(`${URL}/activate-deactivate-user`, {
                    method: "POST",
                    body: JSON.stringify(userStatusEach),
                    headers: header_var
                })
                    .then(response => response.json())
                    .then(json => {
                        // callback(null, json);
                        result.push(json);
                        console.log(result)
                    }
                    )
            });
            //     },
            // ], function (err, results) {
            //     console.log("final response", results);
            // });
        }, 2000)
        dispatch({ type: UPDATE_USER_STATUS_IN_ALL });
    }
}


