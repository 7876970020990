import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginComponent from '../../components/Login/LoginComponent';
import { backImg } from '../../css/styles';
import { getCookies } from '../../components/Login/LoginAction';


function LoginPage() {
    const navigate = useNavigate();
    useEffect(() => {
        const userInfo = getCookies('LoginDetails')
        // console.log("login", userInfo === '', typeof (userInfo))
        if (typeof (userInfo) === 'object') {
            navigate('/dashboard')
        }
    }, [])

    return (
        <Box style={backImg.paperContainer} >
            <LoginComponent />
        </Box>
    );
}

export default LoginPage;
