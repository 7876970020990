import {
  Collapse,
  Toolbar,
  ListItemText,
  ListItem,
  Box,
  List,
  Drawer,
  ListItemButton,
} from '@mui/material';
import {
  select,
} from '../../css/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { drawerWidth } from './Header';
import {
  useLocation,
  Link
} from 'react-router-dom';
import '../../css/style.css'
import {
  useEffect,
  useState
} from 'react';
import { getCookies } from '../Login/LoginAction';


function SideDrawer(props) {
  const location = useLocation();
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const userInfo = getCookies('LoginDetails')


  //*********** Drawer collapse list*******
  const handleCollapseToggle1 = () => {
    setOpenCollapse1(!openCollapse1);
  };

  // **********Side Drawer list

  useEffect(() => {
    if (location.pathname === '/dashboard/users' || location.pathname === '/dashboard/users/addNewUser') {
      handleCollapseToggle1();
    }
  }, [])
  const drawer = (
    <div>
      {userInfo &&
        <List>
          <ListItem disablePadding sx={{
            width: 'auto',
            '&.MuiListItem-root': { '&:hover': { bgcolor: '#b4e3ff' } },
            '&.MuiListItem-root.Mui-selected': { bgcolor: '#fff', borderRadius: '10px', margin: '5px', boxShadow: 1 }
          }}
            component={Link} to='/dashboard'
            selected={'/dashboard' === location.pathname} classes={{ selected: select.active }}>
            <ListItemButton >
              <ListItemText sx={{ color: '#000' }}>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>

          {/* <StyleCommonDivider /> */}

          {userInfo.data.role_id === 1 &&
            <>
              <ListItem disablePadding sx={{ '&.MuiListItem-root': { '&:hover': { bgcolor: '#b4e3ff' } }, }}>
                <ListItemButton
                  onClick={handleCollapseToggle1}>
                  <ListItemText sx={{ color: '#000', }}>
                    Users
                    {!openCollapse1 ? <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: '150px', color: '#003a5d' }} />
                      : <FontAwesomeIcon icon={faCaretUp} style={{ marginLeft: '150px', color: '#003a5d' }} />}
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <Collapse in={openCollapse1} timeout="auto" unmountOnExit >
                <ListItem disablePadding sx={{
                  width: 'auto',
                  '&.MuiListItem-root': { '&:hover': { bgcolor: '#b4e3ff' } },
                  '&.MuiListItem-root.Mui-selected': { bgcolor: '#fff', borderRadius: '10px', margin: '5px', boxShadow: 1 }
                }}
                  component={Link} to='/dashboard/users/addNewUser' selected={'/dashboard/users/addNewUser' === location.pathname}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText sx={{ color: '#000' }}>Add New</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Collapse>

              <Collapse in={openCollapse1} timeout="auto" unmountOnExit >
                <ListItem disablePadding sx={{
                  width: 'auto',
                  '&.MuiListItem-root': { '&:hover': { bgcolor: '#b4e3ff' } },
                  '&.MuiListItem-root.Mui-selected': { bgcolor: '#fff', borderRadius: '10px', margin: '5px', boxShadow: 1 }
                }}
                  component={Link} to='/dashboard/users' selected={'/dashboard/users' === location.pathname}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText sx={{ color: '#000' }}>Users</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Collapse>

              {/* <StyleCommonDivider /> */}

              <ListItem disablePadding sx={{
                width: 'auto',
                '&.MuiListItem-root': { '&:hover': { bgcolor: '#b4e3ff' } },
                '&.MuiListItem-root.Mui-selected': { bgcolor: '#fff', borderRadius: '10px', margin: '5px', boxShadow: 1 }
              }}
                component={Link} to='/dashboard/projectList'
                selected={'/dashboard/projectList' === location.pathname}>
                <ListItemButton>
                  <ListItemText sx={{ color: '#000' }}>Projects</ListItemText>
                </ListItemButton>
              </ListItem>

              {/* <StyleCommonDivider /> */}
            </>}

        </List>
      }
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, overflow: 'auto' }}
      aria-label="mailbox folders"
    >

      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: '#dbf2ff', },
        }}
      >
        <Toolbar />

        <Toolbar sx={{ color: '#003a5d', display: 'flex', justifyContent: 'right' }}>
          <FontAwesomeIcon icon={faXmark} onClick={props.handleDrawerToggle} />
        </Toolbar>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: '#dbf2ff', },
        }}
        open
      >
        <Toolbar sx={{ marginTop: '10px' }} />
        {drawer}
      </Drawer>
    </Box>
  );
}

export default SideDrawer;
