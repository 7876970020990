import { VIEW_ALL_ROLES } from "./UserRegistrationActionType";

const userRegistrationObj = {
    allRoles: []
};

const userRegistrationReducer = (state = userRegistrationObj, action) => {

    switch (action.type) {
        case VIEW_ALL_ROLES:
            return {
                ...state, allRoles: action.payload
            };
        default:
            return state;
    }
};

export default userRegistrationReducer;
