import { Box } from '@mui/material';
import React from 'react';
import DashboardComponents from '../../components/Dashboard/DashboardComponents';
import Header from '../../components/Header/Header';

function Dashboard() {
    return (
        <Box>
            <Header />
            <DashboardComponents />
        </Box>
    );
}

export default Dashboard;
