import { VIEW_ALL_USERS } from "./UsersActionType";

const userObj = {
    userList: []
};

const userReducer = (state = userObj, action) => {

    switch (action.type) {
        case VIEW_ALL_USERS:
            return {
                ...state, userList: action.payload
            };
        default:
            return state;
    }
}

export default userReducer;
