import React, { useState, } from 'react';
import { useDispatch, } from 'react-redux';
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
} from '@mui/material';
import {
    StyleCard,
    StyleFlexBox,
    StyleCommonButton
} from '../../css/styles';
import '../../css/style.css'
import {
    authLogin,
} from './LoginAction';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LoginComponent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [loginDetails, setLoginDetails] = useState({
        username: '',
        password: '',
    });

    const [error, setError] = useState({
        username: '',
        password: '',
    })

    function navToDashboard() {
        navigate('/dashboard')
    }

    function handleLoginDetails(e) {
        let value = e.target.value;
        let name = e.target.name;
        setLoginDetails({ ...loginDetails, [name]: value });
        setError({ ...error, [name]: '' })
    }

    function handleLogin(e) {
        e.preventDefault();

        if (loginDetails.username.length === 0) {
            setError({ ...error, username: 'Please Enter username' })
            if (loginDetails.password === '') {
                setError({ ...error, password: 'Please Enter password', username: 'Please Enter email' })
            }
        } else if (loginDetails.password.length === 0) {
            setError({ ...error, password: 'Please Enter password' })
        }
        else if (loginDetails.username !== 0 && loginDetails.password !== 0) {
            dispatch(authLogin(loginDetails, () => handleClick(e), () => navToDashboard(),));
        }
    }

    const vertical = 'top';
    const horizontal = 'center';

    return (
        <Box>
            <Snackbar open={open} anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Invalid usename or password !!
                </Alert>
            </Snackbar>
            <StyleCard sx={{ boxShadow: 5, }}>
                <Box sx={{ padding: 5 }}>
                    <StyleFlexBox>
                        <Typography component='h6' variant='h4'>Login</Typography>
                    </StyleFlexBox>

                    <form onSubmit={(e) => handleLogin(e)}>
                        {/* <FormControl> */}
                        <TextField size='small' variant='outlined' fullWidth
                            error={error.username ? true : false}
                            helperText={error.username}
                            placeholder='Enter Your Email-ID' type='email'
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </InputAdornment>,
                            }}
                            sx={{ marginTop: '15px' }} name='username' onChange={(e) => handleLoginDetails(e)} />

                        <TextField size='small' variant='outlined' fullWidth
                            error={error.password ? true : false}
                            helperText={error.password}
                            placeholder='Enter Your Password' type='password'
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faLock} />
                                    </InputAdornment>,
                            }}
                            sx={{ marginTop: '15px' }} name='password' onChange={(e) => handleLoginDetails(e)} />

                        <StyleFlexBox sx={{ marginTop: '15px' }}>
                            <StyleCommonButton variant='outlined'
                                sx={{
                                    backgroundColor: "#003a5d",
                                    color: '#fff',
                                    "&.MuiButtonBase-root": {
                                        "&:hover": { backgroundColor: "#003a5d" },
                                    },
                                }}
                                type='submit' >
                                Login
                            </StyleCommonButton>
                        </StyleFlexBox>
                        {/* </FormControl> */}
                    </form>
                    <StyleFlexBox component={Link} to='/resetPassword' sx={{
                        marginTop: '15px',
                        color: 'blue',
                        textDecoration: 'none'
                    }}>
                        Forgot Password?
                    </StyleFlexBox>
                </Box>

            </StyleCard>
        </Box>

    );
}

export default LoginComponent;
