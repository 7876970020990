import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Dashboard from './main-component/Dashboard/Dashboard';
import LoginPage from './main-component/LoginPage/LoginPage';
import UsersPage from './main-component/UsersPages/UsersPage';
import ProjectListPage from './main-component/ProjectListPage/ProjectListPage';
import ForgotPassPage from './main-component/ForgotPassword/ForgotPassPage';
import AssignProjectsPage from './main-component/AssignProjects/AssignProjectsPage';
import AddUserPage from './main-component/AddUser/AddUserPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/resetPassword' element={<ForgotPassPage />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/dashboard/users' element={<UsersPage />} />
        <Route path='/dashboard/users/assignProjects' element={<AssignProjectsPage />} />
        <Route path='/dashboard/users/addNewUser' element={<AddUserPage />} />
        <Route path='/dashboard/projectList' element={<ProjectListPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
