import { styled } from '@mui/material/styles';
import {
  Card,
  Button,
  Typography,
  Box,
  tableCellClasses,
  TableCell,
  TableContainer
} from '@mui/material';

export const backImg = {
  paperContainer: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#dbf2ff',
  }
};
export const select = {
  active: {
    backgroundColor: "red !important"
  }
}


export const StyleCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  backgroundColor: "#fff",
  // opacity: 0.8,
  color: '#003a5d',
  // marginTop: '40%',
  display: 'flex',
  justifyContent: 'center',
  width: '370px',
  borderRadius: '0px',

  [theme.breakpoints.only('sm')]: {
    width: '400px',
  },
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
  [theme.breakpoints.up('md')]: {
    width: '550px',
  },
  [theme.breakpoints.only('lg')]: {
    width: '490px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '500px',
  },

}));


// Header styles**************
export const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '120px',
    height: '50px'
  },
  [theme.breakpoints.up('md')]: {
    width: '160px',
    height: '50px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '170px',
    height: '50px',
  },
}));

export const StyleUserName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: 13
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 14
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 16
  },
}));


// Change password modal
export const StyleModalCard = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370,
  // height: 400,
  backgroundColor: '#fff',
  border: '1px',
  borderRadius: '15px',
  textAlign: 'center',
  // padding: '20px 20px 20px 20px'
  // boxShadow: 2,
  // pt: 2,
  // px: 4,
  // pb: 3,
}))



//  *********************
// Common components
export const StyleCommonButton = styled(Button)(({ theme }) => ({
  color: '#003a5d',
  border: '1px solid #003a5d',
  borderRadius: '4px',
  padding: '5px 25px',


  '& .MuiButtonBase-root': {
    border: '1px solid #003a5d',
  }

}));


export const StyleMainDiv = styled(Box)(({ theme }) => ({
  marginLeft: '270px',
  marginTop: '90px',

  [theme.breakpoints.only('sm')]: {
    marginLeft: '280px',
  },
  [theme.breakpoints.only('md')]: {
    marginLeft: '270px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
  },
  // [theme.breakpoints.up('xl')]: {
  //     width: '550px',
  // },
}));

export const StyleFlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}))

export const StyleCommonRedButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  border: '1px solid #d1001f',
  borderRadius: '4px',
  padding: '5px 25px',
  backgroundColor: '#d1001f',

  '& .MuiButtonBase-root': {
    border: '1px solid #d1001f',

  }

}));

export const StyleLoaderBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.only('sm')]: {
    left: '50%',
  },
  [theme.breakpoints.only('md')]: {
    left: '64%',
  },
  [theme.breakpoints.up('lg')]: {
    left: '60%',
  },

}));
// **************************
// Dashboard
export const StyleTiles = styled(Card)(({ theme }) => ({
  backgroundColor: "#003a5d",
  opacity: 0.8,
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '1px',
  borderTopLeftRadius: '25px',
  borderBottomRightRadius: '25px',
  width: '320px',
  height: '170px',
  marginTop: '58px',
  marginRight: '10px',
  marginLeft: '10px',
  flexWrap: 'wrap',
  marginBottom: '55px',
  cursor: 'pointer',
}));

export const StyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  flexWrap: 'wrap',
}));

//Table Styling
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '10px 10px 10px 10px',
  border: '1px solid #ccc',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#003a5d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '0px 0px 0px 0px',
    backgroundColor: '#fff',

  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: 'auto',
  '&.MuiTableContainer-root::-webkit-scrollbar': {
    height: '6px',
    backgroundColor: '#F5F5F5',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5'

  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    // outline: '1px solid slategrey',
    borderRadius: '10px',
  }
}));

// Forgot password card
export const StyleCenterCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  [theme.breakpoints.only('sm')]: {
    width: '400px',
  },
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
  [theme.breakpoints.up('md')]: {
    width: '490px',
  },
  [theme.breakpoints.only('lg')]: {
    width: '490px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '500px',
  },

}));
