import { AUTH_LOGIN } from "./LoginActionType";
import * as CryptoJS from 'crypto-js';

// Authenticate login
export const authLogin = (loginD, handleClick, navToDashboard, loading, setLoading, timer) => {
  return (dispatch, getState) => {

    fetch(`${process.env.REACT_APP_URL}/authenticate`, {
      method: "POST",
      body: JSON.stringify(loginD),
      headers: { "Content-type": "application/json; charset=UTF-8" }
    })
      .then(response => response.json())
      .then(json => {
        console.log(json)
        if (json.status === 'Invalid username or password.') {
          handleClick();
          dispatch({ type: AUTH_LOGIN, });
        } else {
          var Info = { ...json, username: loginD.username, }
          setCookies(Info)
          navToDashboard();
          dispatch({ type: AUTH_LOGIN, payload: Info });
        }
      }).catch((err) => {
        console.log(err)
        console.log("hiii")
        handleClick();
      })
  }
};

// set cookies
function setCookies(Info) {
  const d = new Date();
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  let data = CryptoJS.AES.encrypt(JSON.stringify(Info), 'abc').toString();
  document.cookie = 'LoginDetails' + "=" + JSON.stringify(data) + ";" + expires + ";path=/";
}

//get cookies
export function getCookies(cookieName) {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let decr = CryptoJS.AES.decrypt(JSON.parse(c.substring(name.length, c.length)), 'abc')
      return JSON.parse(decr.toString(CryptoJS.enc.Utf8));
    }
  }
  return "";
}
