import {
    Alert,
    Box,
    Divider,
    Modal,
    Snackbar,
    Typography
} from '@mui/material';
import React from 'react';
import {
    StyleModalCard,
    StyleFlexBox
} from '../../css/styles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

function ForgetSuccessModal(props) {
    const vertical = 'top';
    const horizontal = 'center';
    return (
        <>
            <Snackbar open={props.openError} anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={3000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity="error" sx={{ width: '100%' }}>
                    Invalid e-mail address.
                </Alert>
            </Snackbar>
            <Modal open={props.openSuccessModal} >
                <StyleModalCard>
                    <Box sx={{ color: 'green', margin: 2 }}>
                        <FontAwesomeIcon icon={faCircleCheck} className='fa-3x' />
                    </Box>
                    <Typography marginTop={2}>
                        Password successfully send to your e-mail address.
                    </Typography>
                    <Divider />
                    <StyleFlexBox component={Link} to='/'
                        sx={{
                            color: 'blue',
                            textDecoration: 'none', marginTop: 2, marginBottom: 2
                        }}>
                        Ok
                    </StyleFlexBox>

                </StyleModalCard>
            </Modal>
        </>
    );
}

export default ForgetSuccessModal;
