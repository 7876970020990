import { Box } from '@mui/material';
import React from 'react';
import Header from '../../components/Header/Header';
import ProjectList from '../../components/ProjectList/ProjectList';

function ProjectListPage() {
    return (
        <Box>
            <Header />
            <ProjectList />
        </Box>
    );
}

export default ProjectListPage;
