import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import userRegistrationReducer from '../../components/AddNewUser/UserRegistrationReducer';
import projectsModuleReducer from '../../components/AssignProjects/AssignProjectsReducer';
import dashboardReducer from '../../components/Dashboard/DashboardReducer';
import userReducer from '../../components/Users/UsersReducer';


const middleware = applyMiddleware(thunkMiddleWare)
const rootreducer = combineReducers({
    dashboard: dashboardReducer,
    users: userReducer,
    userProjectModules: projectsModuleReducer,
    addUser: userRegistrationReducer,
})
const store = createStore(rootreducer, middleware);
// console.log(store)
export default store;

// import { configureStore } from '@reduxjs/toolkit';
// import authReducer from './Auth-slice'
// const store = configureStore({
//     reducer: authReducer,
// });
// export default store;
