import {
    GET_ACTIVE_PROJECTS,
    ORDER_AUDIT_PASS_PRESENT,
    PROJECTS_BACKEND_LINK,
    PROJECT_DROPDOWN,
    UPDATE_PROJECT_MODULE_LIST,
    VIEW_USER_PROJECT_MODULE
} from "./AssignProjectActionType";

const projectModuleObj = {
    projectModuleList: [],
    dropdownList: [],
    projectBackLink: [],
    activeProjectList: [],
    passPresent: false,
};

const projectsModuleReducer = (state = projectModuleObj, action) => {

    switch (action.type) {
        case VIEW_USER_PROJECT_MODULE:
            return {
                ...state, projectModuleList: action.payload
            };
        case UPDATE_PROJECT_MODULE_LIST:
            return {
                ...state, projectModuleList: action.payload
            };
        case PROJECT_DROPDOWN:
            return {
                ...state, dropdownList: action.payload
            };
        case PROJECTS_BACKEND_LINK:
            return {
                ...state, projectBackLink: action.payload
            };
        case PROJECT_DROPDOWN:
            return {
                ...state, dropdownList: action.payload
            };
        case GET_ACTIVE_PROJECTS:
            return {
                ...state, activeProjectList: action.payload
            };
        case ORDER_AUDIT_PASS_PRESENT:
            return {
                ...state, passPresent: action.payload
            };
        default:
            return state;
    }
}

export default projectsModuleReducer;
