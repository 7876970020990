import React from 'react';
import AssignProjectForm from '../../components/AssignProjects/AssignProjectForm';
import Header from '../../components/Header/Header'

function AssignProjectsPage() {
  return (
    <>
      <Header />
      <AssignProjectForm />
    </>
  );
}

export default AssignProjectsPage;
