import React from 'react';
import Header from '../../components/Header/Header'
import UsersList from '../../components/Users/UsersList';

function UsersPage() {
    return (
        <>
            <Header />
            <UsersList />
        </>
    );
}

export default UsersPage;
