import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    StyleCenterCard,
    StyleCommonButton,
    StyleFlexBox
} from '../../css/styles';
import ForgetSuccessModal from './ForgetSuccessModal';
import { forgotPassword } from './ForgotPassAction';

function ForgotPassCard() {
    // suceess popup modal
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const handleOpenPassModal = () => {
        setOpenSuccessModal(true);
    };

    // failure alert
    const [openError, setOpenError] = useState(false);

    const handleClick = () => {
        setOpenError(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    function handleResetPassword(e) {
        const emailDetails = {
            email_id: email,
        }
        dispatch(forgotPassword(emailDetails, () => handleOpenPassModal(), () => handleClick()))
    }
    return (
        <>
            <StyleCenterCard sx={{ width: '360px', boxShadow: 5 }}>
                <Box sx={{ padding: 5, }}>
                    <Typography component='h2' variant='h4' color='#003a5d'>
                        Forgot Password
                    </Typography>

                    <Typography marginTop={1}>
                        Please enter the email you use to sign in.
                    </Typography>

                    <StyleFlexBox marginTop={3}>
                        <TextField size='small' type='email' variant='outlined'
                            placeholder='Your e-mail address' fullWidth
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </InputAdornment>
                            }}
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                    </StyleFlexBox>

                    <StyleFlexBox marginTop={2} marginBottom={2}>
                        <StyleCommonButton variant='contained' fullWidth
                            sx={{
                                backgroundColor: "#003a5d",
                                color: '#fff',
                                "&.MuiButtonBase-root": {
                                    "&:hover": { backgroundColor: "#003a5d" },
                                },
                            }}
                            onClick={(e) => handleResetPassword(e)} >
                            Reset My Password
                        </StyleCommonButton>
                    </StyleFlexBox>
                    <Typography component={Link} to='/'
                        sx={{
                            color: 'blue',
                            textDecoration: 'none', fontSize: '13px'
                        }}>
                        Back to Sign in
                    </Typography>

                </Box>
            </StyleCenterCard>
            {/* Success Modal */}
            <ForgetSuccessModal openSuccessModal={openSuccessModal} openError={openError} handleClose={handleClose} />

        </>
    );
}

export default ForgotPassCard;
