import * as React from "react";
import { StyleFlexBox, StyleLoaderBox, StyleMainDiv } from "../../css/styles";
import { CircularProgress, Container, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deactivateUserStatusinProject,
  deleteUserProject,
  projectDropdown,
  updateFormFieldsinAllProjects,
  updateProjectModuleList,
  updateUserProjectModules,
  viewUserProjectModule,
} from "./AssignProjectsAction";
import RegistrationFormComponent from "./RegistrationFormComponent";
import { useRef } from "react";
import AssignProjectsModals from "./AssignProjectsModals";
import { getCookies } from "../Login/LoginAction";
import { ORDER_AUDIT_PASS_PRESENT } from "./AssignProjectActionType";

export default function AssignProjectForm() {
  const userData = JSON.parse(localStorage.getItem('User_Data'));
  // console.log(userData)
  const userInfo = getCookies("LoginDetails");
  const dispatch = useDispatch();
  const projectModuleList = useSelector((state) => state.userProjectModules.projectModuleList);
  const passPresent = useSelector((state) => state.userProjectModules.passPresent); //check password is present or not for Order Audit project 
  // console.log('passPresent', passPresent)
  const dropdownList = useSelector(state => state.userProjectModules.dropdownList);
  // const [dropdownList, setDropdownList] = ([dropdownList1])
  console.log('compoent dropdown list', dropdownList);

  const [userProjectModuleDetails, setUserProjectModuleDetails] = useState([]);
  const [deleteProjectDetails, setDeleteProjectDetails] = useState({});
  const [fieldObj, setFieldObj] = useState([]);
  const [userStatus, setUserStatus] = useState({})

  // loader on component mount
  const [loading, setLoading] = useState(false);
  const timer = useRef();

  useEffect(() => {
    fieldObj.length = 0;
    const id = {
      user_id: userData.id,
    };
    dispatch(viewUserProjectModule(userInfo, id)); //get all project module details
    dispatch(projectDropdown(userInfo))
    //loader
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
    // console.log("did mount", fieldObj)
  }, []);

  useEffect(() => {
    // create form object for mapping
    fieldObj.length = 0;
    projectModuleList.map((value, index) => {
      var fieldValue = [];
      var fieldID = [];
      var userfieldID = [];
      var formField = [];
      var mandatory_fields = [];
      var fieldType = [];
      if (value.user_field_value !== null) {
        value.user_field_value.split(",").map((v5) => fieldValue.push(v5));
      }
      if (value.field_id !== null) {
        value.field_id.split(",").map((v6) => fieldID.push(v6));
      }
      if (value.user_field_id !== null) {
        value.user_field_id.split(",").map((v7) => userfieldID.push(v7));
      }
      if (value.form_field !== null) {
        value.form_field.split(",").map((v8) => formField.push(v8));
      }
      if (value.is_mandatory !== null) {
        value.is_mandatory.split(",").map((v3) => mandatory_fields.push(v3));
      }
      if (value.field_type !== null) {
        value.field_type.split(",").map((v4) => fieldType.push(v4));
      }
      let i;
      for (i = 0; i <= fieldID.length; i++) {
        let proInd = fieldObj.findIndex(
          (e) => e.project_id === value.project_id
        );
        if (proInd === -1) {
          fieldObj.push(...fieldObj, {
            project_id: value.project_id,
            form_fields: [],
          });
        } else if (proInd !== -1) {
          fieldID.map((v, i) => {
            fieldObj[proInd].form_fields.push({
              fieldID: v,
              formField: formField[i],
              fieldValue: "",
              is_mandatory: mandatory_fields[i],
              field_type: fieldType[i],
            });
          });

          if (value.user_field_value === null) {
            fieldObj[proInd].form_fields.map((val1, ind1) =>
              userfieldID.map((val2, ind2) =>
                val1.fieldID === val2
                && (fieldObj[proInd].form_fields[ind1].fieldValue = "")
              )
            );
          } else {
            fieldObj[proInd].form_fields.map((val1, ind1) =>
              userfieldID.map((val2, ind2) =>
                val1.fieldID === val2
                  ? (fieldObj[proInd].form_fields[ind1].fieldValue =
                    fieldValue[ind2])
                  : ""
              )
            );
          }


          const unique = fieldObj[proInd].form_fields
            .map((e) => e.fieldID)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => fieldObj[proInd].form_fields[e])
            .map((e) => fieldObj[proInd].form_fields[e]);
          fieldObj[proInd].form_fields = unique;
          setFieldObj(fieldObj);
        }
        const unique = fieldObj
          .map((e) => e.project_id)
          .map((e, i, final) => final.indexOf(e) === i && i)
          .filter((e) => fieldObj[e])
          .map((e) => fieldObj[e]);
        setFieldObj(unique);
      }
    });
  }, [projectModuleList]);

  // component unmounting 
  useEffect(() => {
    return () => { //for cleanup
      // clear storage when 
      dispatch({ type: ORDER_AUDIT_PASS_PRESENT, payload: false });
    }
  }, [])
  // Confirmaion Modal
  const [confirmationModal, setConfirmationModal] = useState(false);
  function handleOpenConfirmation(project_id) {
    setConfirmationModal(true);
    const deleteDetails = {
      user_id: userData.id,
      user_project_id: project_id,
    };
    setDeleteProjectDetails({ ...deleteDetails });
    // console.log(deleteProjectDetails)
    setUserStatus({
      user_email: userData.email_id,
      active_user_flag: true,
      user_id: userData.id,
      project_id: project_id
    });

  }
  function handleCloseConfirmation(e, para) {
    const index = projectModuleList.findIndex(
      (e) =>
        Number(e.user_project_id) ===
        Number(deleteProjectDetails.user_project_id)
    );
    if (para === "Yes") {

      dispatch(deactivateUserStatusinProject(userInfo, userStatus))
      projectModuleList[index].user_project_id = null;
      projectModuleList[index].user_module_id = null;
      setConfirmationModal(false);
      dispatch(
        deleteUserProject(userInfo, deleteProjectDetails, () =>
          handleAlertClick(),
        )
      );

      // loader for state updation
      setTimeout(function () {
        if (!loading) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      }, 500)

    } else {
      setConfirmationModal(false);
    }
  }

  // Alert Box
  const [successOpen, setSuccessOpen] = useState(false);

  // Alert box open
  function handleAlertClick() {
    setSuccessOpen(true);
  }
  // Alert box close
  function handleAlertClose() {
    setSuccessOpen(false);
  }

  // Error Modal
  const [errorMessage, setErrorMessage] = useState("");
  const [openError, setOpenError] = useState(false);

  function handleOpenError(para) {
    setOpenError(true);
    setErrorMessage(para);
  }
  function handleCloseError() {
    setOpenError(false);
  }




  function handleChange(e, index, para, project_id, field_id) {
    let value = e.target.value;
    // console.log('vale', typeof (value))
    // find index
    const ind = userProjectModuleDetails.findIndex(
      (e) => Number(e.user_project_id) === Number(project_id)
    );

    switch (para) {
      case "Project":
        if (e.target.checked) {
          projectModuleList[index].user_project_id = value;
          setUserProjectModuleDetails([
            ...userProjectModuleDetails,
            {
              user_id: userData.id,
              user_project_id: value,
              modules: [],
              additionalFields: [],
            },
          ]);
        } else if (e.target.checked === false) {
          //******* delete project from store
          if (ind === -1) {
            handleOpenConfirmation(project_id);
          } else {
            projectModuleList[index].user_project_id = null;
            projectModuleList[index].user_module_id = null;
            userProjectModuleDetails.splice(ind, 1);
            setUserProjectModuleDetails([...userProjectModuleDetails]);
          }
        }
        break;

      case "Module":
        if (e.target.checked) {
          // for store updation
          if (projectModuleList[index].user_module_id === null) {
            projectModuleList[index].user_module_id = value + ",";
          } else {
            projectModuleList[index].user_module_id =
              projectModuleList[index].user_module_id + value + ",";
          }

          // console.log(project_id)
          if (ind === -1) {
            setUserProjectModuleDetails([
              ...userProjectModuleDetails,
              {
                user_id: userData.id,
                user_project_id: project_id,
                modules: [
                  {
                    user_module_id: value,
                    is_checked: 1,
                  },
                ],
                additionalFields: [],
              },
            ]);
          } else {
            if (
              userProjectModuleDetails[ind].modules.findIndex(
                (e) => Number(e.user_module_id) === Number(value)
              ) === -1
            ) {
              userProjectModuleDetails[ind].modules.push({
                user_module_id: value,
                is_checked: 1,
              });

              setUserProjectModuleDetails([...userProjectModuleDetails]);
            } else {
              userProjectModuleDetails[ind].modules.splice(
                userProjectModuleDetails[ind].modules.findIndex(
                  (e) => Number(e.user_module_id) === Number(value)
                ),
                1
              );
              userProjectModuleDetails[ind].modules.push({
                user_module_id: value,
                is_checked: 1,
              });
              setUserProjectModuleDetails([...userProjectModuleDetails]);
            }
          }
        } else if (e.target.checked === false) {
          projectModuleList[index].user_module_id = projectModuleList[
            index
          ].user_module_id.replace(`${value},`, "");
          if (ind === -1) {
            setUserProjectModuleDetails([
              ...userProjectModuleDetails,
              {
                user_id: userData.id,
                user_project_id: project_id,
                modules: [
                  {
                    user_module_id: value,
                    is_checked: 0,
                  },
                ],
              },
            ]);
          } else {
            if (
              userProjectModuleDetails[ind].modules.findIndex(
                (e) => Number(e.user_module_id) === Number(value)
              ) === -1
            ) {
              userProjectModuleDetails[ind].modules.push({
                user_module_id: value,
                is_checked: 0,
              });

              setUserProjectModuleDetails([...userProjectModuleDetails]);
            } else {
              userProjectModuleDetails[ind].modules.splice(
                userProjectModuleDetails[ind].modules.findIndex(
                  (e) => Number(e.user_module_id) === Number(value)
                ),
                1
              );
              setUserProjectModuleDetails([...userProjectModuleDetails]);
            }
          }
        }
        break;

      case "Registration":
        const objInd = fieldObj[index].form_fields.findIndex(
          (e) => Number(e.fieldID) === Number(field_id)
        );
        fieldObj[index].form_fields[objInd].fieldValue = value;
        setFieldObj(fieldObj);
        var fieldValue = [];
        var fieldID = [];
        if (projectModuleList[index].user_field_value !== null) {
          projectModuleList[index].user_field_value
            .split(",")
            .map((v4) => fieldValue.push(v4));
        }
        if (projectModuleList[index].user_field_id !== null) {
          projectModuleList[index].user_field_id
            .split(",")
            .map((v4) => fieldID.push(v4));
        }
        if (projectModuleList[index].user_field_id === null) {
          projectModuleList[index].user_field_id = field_id;
          projectModuleList[index].user_field_value = value.toString();
          // console.log("hiiiii")
        } else if (fieldID.indexOf(field_id) === -1) {
          projectModuleList[index].user_field_id =
            projectModuleList[index].user_field_id + "," + field_id;
          projectModuleList[index].user_field_value =
            projectModuleList[index].user_field_value + "," + value;
        } else {
          projectModuleList[index].user_field_id.replace(/fieldID/g, fieldID);
          var find = fieldID.indexOf(field_id);
          // console.log(fval)
          fieldValue[find] = value;
          // console.log("new", fieldValue)
          // console.log(fieldValue.join(","))
          projectModuleList[index].user_field_value = fieldValue.join(",");
        }
        if (ind === -1) {
          // projectModuleList[index].user_project_id = project_id;
          setUserProjectModuleDetails([
            ...userProjectModuleDetails,
            {
              user_id: userData.id,
              user_project_id: project_id,
              modules: [],
              additionalFields: [
                {
                  user_field_id: field_id,
                  field_value: value,
                },
              ],
            },
          ]);
        } else {
          const field_index = userProjectModuleDetails[
            ind
          ].additionalFields.findIndex(
            (e) => Number(e.user_field_id) === Number(field_id)
          );
          if (field_index === -1) {
            userProjectModuleDetails[ind].additionalFields.push({
              user_field_id: field_id,
              field_value: value,
            });
            setUserProjectModuleDetails([...userProjectModuleDetails]);
          } else {
            userProjectModuleDetails[ind].additionalFields[field_index] = {
              user_field_id: field_id,
              field_value: value,
            };
            setUserProjectModuleDetails([...userProjectModuleDetails]);
          }
        }

        break;

      default:
        break;
    }
    // console.log("final", userProjectModuleDetails)
    dispatch(updateProjectModuleList(projectModuleList));
  }

  function handleSubmitForm(e, project_id, index1) {
    const index = userProjectModuleDetails.findIndex(
      (e) => Number(e.user_project_id) === Number(project_id)
    );
    const storeindex = projectModuleList.findIndex(
      (e) => Number(e.user_project_id) === Number(project_id)
    );

    let condition = true;
    if (userProjectModuleDetails[index].modules.length === 0) {
      if (
        projectModuleList[storeindex].user_module_id === null ||
        projectModuleList[storeindex].user_module_id === ""
      ) {
        handleOpenError("Please select at least one module.");
        condition = false;
      }
    } else if (userProjectModuleDetails[index].modules.length !== 0) {
      for (let i = 0; i <= fieldObj[storeindex].form_fields.length; i++) {
        if (fieldObj[storeindex].form_fields[i]) {
          if (fieldObj[storeindex].form_fields[i].is_mandatory === "1") {
            if (fieldObj[storeindex].form_fields[i].fieldValue === "") {
              handleOpenError("Please fill the mandatory fields.");
              condition = false;
            }
          }
        }
      }
      if (project_id == 4) {
        // validation for order audit project check password and confirm password are same and both are filled
        for (let i = 0; i <= fieldObj[storeindex].form_fields.length; i++) {
          if (fieldObj[storeindex].form_fields[i]) {
            let indexofUserRole = fieldObj[storeindex].form_fields.findIndex(e => e.fieldID == 13)
            let indexofPass = fieldObj[storeindex].form_fields.findIndex(e => e.fieldID == 26)
            let indexofConfirmPass = fieldObj[storeindex].form_fields.findIndex(e => e.fieldID == 27)
            if (fieldObj[storeindex].form_fields[indexofUserRole].fieldValue == 2) {
              if (fieldObj[storeindex].form_fields[indexofPass].fieldValue == '' || fieldObj[storeindex].form_fields[indexofConfirmPass].fieldValue == '') {
                condition = false
                console.log("1", condition)
                handleOpenError("Please fill the mandatory fields.");
              } else if (fieldObj[storeindex].form_fields[indexofPass].fieldValue.length !== 0 || fieldObj[storeindex].form_fields[indexofConfirmPass].fieldValue.length !== 0) {
                if (fieldObj[storeindex].form_fields[indexofPass].fieldValue !== fieldObj[storeindex].form_fields[indexofConfirmPass].fieldValue) {
                  condition = false
                  console.log("2", condition)
                  handleOpenError("The password confirmation does not match.");
                }

              }
            }

          }
        }
      }

    }

    // set object for save form values in all project
    var registrationAllProjects = {
      email_id: userData.email_id,
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone_no: userData.phone_no,
    };

    fieldObj.filter(e => e.project_id === JSON.parse(project_id)).map((item, position) => {

      for (let i = 0; i < item.form_fields.length; i++) {
        registrationAllProjects = { ...registrationAllProjects, [item.form_fields[i].formField.toLowerCase()]: item.form_fields[i].fieldValue.toString() };
      }
    });



    // submit form when all validation is done
    if (condition === true) {
      // submit form for launch-pad
      dispatch(
        updateUserProjectModules(
          userInfo,
          userProjectModuleDetails[index],
          () => handleAlertClick()
        )
      );
      // console.log(userProjectModuleDetails[index]);

      // submit form in All project DB
      registrationAllProjects['launchpad_token'] = userInfo.data.jwt;
      dispatch(updateFormFieldsinAllProjects(userInfo, index1, registrationAllProjects))
      // console.log(registrationAllProjects)

      // update Order Audit password to launchpad 
      if (project_id == 4) {
        let orderAuditObj = projectModuleList.filter(e => e.project_id == 4)
        if (orderAuditObj[0].user_project_id !== null) {
          if (passPresent == false) {
            let orderAudit = fieldObj.filter(e => e.project_id == 4)
            console.log("orderAudit", orderAudit)
            let orderAuditfieldobj = orderAudit[0].form_fields.filter(e => e.fieldID == 26);
            console.log("orderAuditfieldobj", orderAuditfieldobj)
            const orderAuditPassData = {
              user_id: userData.id,
              password: orderAuditfieldobj[0].fieldValue,
            }
            console.log("orderAuditPassData", orderAuditPassData)
            fetch(`${process.env.REACT_APP_URL}/updatePasswordAuditor`, {
              method: "POST",
              body: JSON.stringify(orderAuditPassData),
              headers: {
                "Content-type": "application/json",
                "Authorization": `Token ${userInfo.data.jwt}`,
                "ngrok-skip-browser-warning": "true"
              }
            })
              .then(response => response.text())
              .then(response4orderAudit => {
                console.log("response4orderAudit", response4orderAudit)
                const id = {
                  user_id: userData.id,
                };
                dispatch(viewUserProjectModule(userInfo, id));
              })
          }
        }
      }
    }
  };

  return (
    <StyleMainDiv>
      <Container
        component={Paper}
        maxWidth="md"
        sx={{
          paddingLeft: { sm: "0px", xs: "0px" },
          paddingRight: { sm: "0px", xs: "0px" },
        }}
      >
        <StyleFlexBox sx={{ backgroundColor: "#003a5d", color: "#fff" }}>
          <Typography margin="5px">Assign Projects</Typography>
        </StyleFlexBox>
        {loading ? (
          <StyleLoaderBox>
            <CircularProgress sx={{ color: "#003a5d" }} ref={timer} />
          </StyleLoaderBox>
        ) : (
          <>
            <RegistrationFormComponent
              fieldObj={fieldObj}
              handleChange={handleChange}
              handleSubmitForm={handleSubmitForm}
              confirmationModal={confirmationModal}
              handleOpenConfirmation={handleOpenConfirmation}
              handleCloseConfirmation={handleCloseConfirmation}
              successOpen={successOpen}
              handleAlertClose={handleAlertClose}
              projectModuleList={projectModuleList}
              dropdownList={dropdownList}
              passPresent={passPresent} //check password is present or not for Order Audit project 
            />
            <AssignProjectsModals
              confirmationModal={confirmationModal}
              handleOpenConfirmation={handleOpenConfirmation}
              handleCloseConfirmation={handleCloseConfirmation}
              successOpen={successOpen}
              handleAlertClose={handleAlertClose}
              errorMessage={errorMessage}
              handleCloseError={handleCloseError}
              openError={openError}
            />
          </>
        )}
      </Container>
    </StyleMainDiv>
  );
}
