export const VIEW_USER_PROJECT_MODULE = "viewUserProjectModule";
export const UPDATE_PROJECT_MODULE_LIST = "updateProjectModuleList";
export const UPDATE_USER_PROJECT_MODULES = "updateUserProjectModules";
export const PROJECT_DROPDOWN = "projectDropdown";
export const DELETE_USER_PROJECT = "deleteUserProject";
export const PROJECTS_BACKEND_LINK = 'projectsBackendLink';
export const UPDATE_FORM_FIELDS_IN_ALL_PROJECTS = 'updateFormFieldsinAllProjects';
export const GET_ACTIVE_PROJECTS = 'getActiveProjects';
export const DEACTIVATE_USER_STATUS_IN_PROJECT = 'deactivateUserStatusinProject';
export const ORDER_AUDIT_PASS_PRESENT = 'orderAuditPassPresent';
