import * as React from 'react';
import {
  StyleModalCard,
  StyleCommonRedButton,
  StyleFlexBox,
  StyleMainDiv
} from '../../css/styles';
import {
  Container,
  Paper,
  TextField,
  Typography,
  Select,
  Grid,
  MenuItem,
  Alert,
  Snackbar,
  Modal,
  Box,
} from '@mui/material';
import { useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  userRegistration,
  viewAllRoles
} from './UserRegistrationAction';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookies } from '../Login/LoginAction';

function AddUserForm() {
  const userInfo = getCookies('LoginDetails');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allRoles = useSelector(state => state.addUser.allRoles);
  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email_id: '',
    phoneNumber: '',
    active_user_flag: true,
    user_role_id: '',
  });


  const [errorOpen, setErrorOpen] = useState(false);
  // Alert box open
  const handleAlertClick = () => {
    setErrorOpen(true);
  };
  // Alert box close
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
  };
  const vertical = 'top';
  const horizontal = 'center';

  function handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    setUserDetails({ ...userDetails, [name]: value });
  }

  function addNewUser() {
    if (userDetails.first_name.length === 0 || userDetails.last_name.length === 0 || userDetails.phoneNumber.length === 0
      || userDetails.email_id.length === 0 || userDetails.user_role_id === '') {
      handleOpenValiadationModal();
    } else if (userDetails.first_name.length !== 0 && userDetails.last_name.length !== 0 && userDetails.phoneNumber.length !== 0
      && userDetails.email_id.length !== 0 && userDetails.user_role_id !== 0) {
      dispatch(userRegistration(userInfo, userDetails, () => navToUserList(), () => handleAlertClick()))
    }
  };

  function navToUserList() {
    navigate('/dashboard/users');
  };

  useEffect(() => {
    dispatch(viewAllRoles(userInfo))
  }, []);

  // Validation Modal
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const handleOpenValiadationModal = () => {
    setOpenValidationModal(true);
  };

  const handleCloseValiadationModal = () => {
    setOpenValidationModal(false);
  };
  return (
    <>
      {/* error alert box */}
      <Snackbar open={errorOpen} anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={3000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          User already exist. Please try with another one.
        </Alert>
      </Snackbar>

      {/* user registration form */}
      <StyleMainDiv>
        <Container component={Paper} maxWidth='md'
          sx={{ paddingLeft: { sm: '0px', xs: '0px' }, paddingRight: { sm: '0px', xs: '0px' }, }}>
          <StyleFlexBox sx={{ backgroundColor: '#003a5d', color: '#fff', }}>
            <Typography margin='5px'>
              Register User
            </Typography>
          </StyleFlexBox>

          <Grid container spacing={2} sx={{ paddingRight: '10%', paddingLeft: '10%', paddingTop: '5%', paddingBottom: '5%' }} >
            <Grid item xs={6}>
              <Typography>First Name<label style={{ color: 'red' }}>*</label></Typography>
              <TextField size='small' type='text' fullWidth value={userDetails.first_name} name='first_name'
                onChange={(e) => handleChange(e)} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Last Name<label style={{ color: 'red' }}>*</label></Typography>
              <TextField size='small' type='text' fullWidth value={userDetails.last_name} name='last_name'
                onChange={(e) => handleChange(e)} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Phone No.<label style={{ color: 'red' }}>*</label></Typography>
              <TextField size='small' type='text' fullWidth value={userDetails.phoneNumber} name='phoneNumber'
                onChange={(e) => handleChange(e)} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Email Id<label style={{ color: 'red' }}>*</label></Typography>
              <TextField size='small' type='email' fullWidth value={userDetails.email_id} name='email_id'
                onChange={(e) => handleChange(e)} />
            </Grid>
            <Grid item xs={6}>
              <Typography>Select User Role<label style={{ color: 'red' }}>*</label></Typography>
              <Select size='small' variant='outlined' fullWidth value={userDetails.user_role_id}
                name='user_role_id' onChange={(e) => handleChange(e)}>
                {allRoles.map((value, index) => (
                  <MenuItem key={index} value={value.role_id}>{value.role}</MenuItem>
                ))}
              </Select>

            </Grid>
          </Grid>

          <StyleFlexBox paddingBottom='2%'>
            <StyleCommonRedButton variant="contained" onClick={(e) => addNewUser(e)}
              sx={{ '&.MuiButtonBase-root': { '&:hover': { backgroundColor: '#d1001f' } } }}>
              Register
            </StyleCommonRedButton>
          </StyleFlexBox>

        </Container>
      </StyleMainDiv>

      {/* validation modal */}
      <Modal
        open={openValidationModal}>
        <StyleModalCard>
          <Box margin={2}>
            All the fields are mandatory.
          </Box>
          <Box margin={2}>
            <StyleCommonRedButton variant='contained' onClick={(e) => handleCloseValiadationModal(e)}
              sx={{ '&.MuiButtonBase-root': { '&:hover': { backgroundColor: '#d1001f' } } }}>

              Ok
            </StyleCommonRedButton>
          </Box>
        </StyleModalCard>
      </Modal>

    </>
  );
};

export default AddUserForm;
