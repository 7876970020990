import {
    DEACTIVATE_USER_STATUS_IN_PROJECT,
    DELETE_USER_PROJECT,
    GET_ACTIVE_PROJECTS,
    ORDER_AUDIT_PASS_PRESENT,
    PROJECTS_BACKEND_LINK,
    PROJECT_DROPDOWN,
    UPDATE_FORM_FIELDS_IN_ALL_PROJECTS,
    UPDATE_PROJECT_MODULE_LIST,
    UPDATE_USER_PROJECT_MODULES,
    VIEW_USER_PROJECT_MODULE
} from "./AssignProjectActionType";
import { getUserProjects, viewAllProjects } from "../Dashboard/DashboardAction";


// show users all project and modules
export const viewUserProjectModule = (userInfo, id) => {
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/getUserProjectModule`, {
            method: "POST",
            body: JSON.stringify(id),
            headers: { "Content-type": "application/json", "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.json())
            .then(projectModuleList => {
                // console.log("projectModuleList", projectModuleList)
                //check password is present or not for Order Audit project 
                let orderAuditObj = projectModuleList.filter(e => e.project_id == 4)
                // console.log("orderAuditObj", orderAuditObj)
                if (orderAuditObj[0].user_field_id !== null) {
                    let passPresent = orderAuditObj[0].user_field_id.includes(26)
                    dispatch({ type: ORDER_AUDIT_PASS_PRESENT, payload: passPresent });
                }


                dispatch({ type: VIEW_USER_PROJECT_MODULE, payload: projectModuleList });
            }
            )
    }
};

// for updating store of projectModuleList
export const updateProjectModuleList = (updatedList) => {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_PROJECT_MODULE_LIST, payload: updatedList });

    }
}

// update users project and modules API
export const updateUserProjectModules = (userInfo, userProjectModuleDetails, handleAlertClick) => {
    // console.log(userProjectModuleDetails)
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/updateUserModule`, {
            method: "PUT",
            body: JSON.stringify(userProjectModuleDetails),
            headers: { "Content-type": "application/json", "Authorization": `Token ${userInfo.data.jwt}`, "ngrok-skip-browser-warning": "true" }
        })
            .then(response => response.text())
            .then(json => {
                if (json === 'Success') {
                    handleAlertClick()
                }
                // console.log(json)
                dispatch({ type: UPDATE_USER_PROJECT_MODULES, });
            }
            )
    }
};

// ERP dropdowns
export const projectDropdown = (userInfo,) => {
    // console.log('projectDropdown')
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/getActiveProjects`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "Authorization": `Token ${userInfo.data.jwt}`,
                "ngrok-skip-browser-warning": "true"
            }
        })
            .then(response => response.json())
            .then(projectDetails => {
                // console.log("......................", activeProjectList)
                let demo = []
                console.log('**********getActiveProjects*', projectDetails)
                var projectBackLink = []
                projectBackLink.length = 0;
                for (let i = 0; i < projectDetails.length; i++) {
                    projectBackLink.push(projectDetails[i].backend_link)
                    console.log("...........getActiveProjects proj", projectBackLink)
                }
                const token = {
                    launchpad_token: userInfo.data.jwt
                }
                console.log(token)
                projectBackLink.forEach((URL, URLindex) => {
                    let header_var = {}
                    if (URLindex === 2) {
                        header_var = { "Content-type": "application/json", }
                    }
                    console.log(header_var)
                    fetch(`${URL}/registration-fields`, {
                        method: "POST",
                        headers: header_var,
                        body: JSON.stringify(token)
                    })
                        .then(response => response.json())
                        .then(dropdownList => {
                            console.log("dropdownList", dropdownList)
                            if (dropdownList.hasOwnProperty('status')) {
                                console.log(dropdownList.status)
                            } else {
                                demo.push(dropdownList)
                                console.log(demo)
                            }
                        }
                        ).catch(err => {
                            console.log("Something went wrong.")
                        })
                })
                dispatch({ type: PROJECT_DROPDOWN, payload: demo });
            }
            )



    }
};


// delete Assign project of user
export const deleteUserProject = (userInfo, deleteProjectDetails, handleAlertClick) => {
    console.log(deleteProjectDetails)
    return (dispatch, getState) => {
        fetch(`${process.env.REACT_APP_URL}/deleteProject`, {
            method: "DELETE",
            body: JSON.stringify(deleteProjectDetails),
            headers: {
                "Content-type": "application/json",
                "Authorization": `Token ${userInfo.data.jwt}`,
                "ngrok-skip-browser-warning": "true"
            }
        })
            .then(response => response.text())
            .then(json => {
                // console.log(json)
                if (json === 'Deleted successfuly.') {
                    setTimeout(function () {
                        handleAlertClick()
                    }, 2500)
                }
                dispatch({ type: DELETE_USER_PROJECT });
            }
            )
    }
};

// project link array store in store
export const projectsBackendLink = (userInfo) => {
    return (dispatch, getState) => {
        dispatch(viewAllProjects(userInfo))
        var projectDetails = getState().dashboard.projectList;
        console.log('***********', projectDetails)
        var projectBackLink = []
        projectBackLink.length = 0;
        for (let i = 0; i < projectDetails.length; i++) {
            projectBackLink.push(projectDetails[i].backend_link)
            console.log("...........proj", projectBackLink)
        }
        dispatch({ type: PROJECTS_BACKEND_LINK, payload: projectBackLink });
    }
};

// update registration fields in all projects
export const updateFormFieldsinAllProjects = (userInfo, index, registrationAllprojects) => {
    console.log("registrationAllprojects", registrationAllprojects)
    return (dispatch, getState) => {
        var projectBackLink = []
        projectBackLink.length = 0;
        fetch(`${process.env.REACT_APP_URL}/getAllProjects`, {
            method: "GET",
            headers: {
                Authorization: `Token ${userInfo.data.jwt}`,
                "ngrok-skip-browser-warning": "true",
            },
        })
            .then((response) => response.json())
            .then((projectList) => {
                console.log("projectList", projectList);

                for (let i = 0; i < projectList.length; i++) {
                    projectBackLink.push(projectList[i].backend_link)
                    console.log("...........proj", projectBackLink)
                }

                // conditionally header set for product portal 
                let header_var = {}
                if (index == 2) {
                    header_var = { "Content-type": "application/json", }
                }
                console.log(header_var)
                fetch(`${projectBackLink[index]}/update-registration-launchpad`, {
                    method: "POST",
                    body: JSON.stringify(registrationAllprojects),
                    // mode: "no-cors",
                    headers: header_var
                })
                    .then(response => response.json())
                    .then(json => {
                        console.log("update-registration-launchpad", json)
                    }
                    )
            });
        dispatch({ type: UPDATE_FORM_FIELDS_IN_ALL_PROJECTS, });

    }
};

// get only active projects
export const getActiveProjects = (userInfo) => {
    // console.log('getActiveProjects')
    return (dispatch, getState) => {
        // dispatch(projectsBackendLink(userInfo))
        fetch(`${process.env.REACT_APP_URL}/getActiveProjects`, {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "Authorization": `Token ${userInfo.data.jwt}`,
                "ngrok-skip-browser-warning": "true"
            }
        })
            .then(response => response.json())
            .then(activeProjectList => {
                // console.log("......................", activeProjectList)
                dispatch({ type: GET_ACTIVE_PROJECTS, payload: activeProjectList });
            }
            )

    }
};

// Deactivate user from project when project is deleted for user
export const deactivateUserStatusinProject = (userInfo, userStatus) => {
    return (dispatch, getState) => {
        var userID = {
            user_id: userStatus.user_id,
        }
        dispatch(getUserProjects(userInfo, userID))
        const userStatusDetails = {
            user_email: userStatus.user_email,
            active_user_flag: userStatus.active_user_flag,
            launchpad_token: userInfo.data.jwt,
        }
        setTimeout(function () { //wait for active project list so set timeout
            const assignProjects = getState().dashboard.getUserProjectsList;
            console.log(assignProjects)
            const projectDetails = assignProjects.filter(e => e.id === userStatus.project_id);
            console.log('***************************', projectDetails)
            fetch(`${projectDetails[0].backend_link}/activate-deactivate-user`, {
                method: "POST",
                body: JSON.stringify(userStatusDetails),
            })
                .then(response => response.json())
                .then(json => {
                    console.log('activate-deactivate-user', json)
                }
                )

        }, 2000)

        dispatch({ type: DEACTIVATE_USER_STATUS_IN_PROJECT });
    }
}
